// src/api.js
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: apiUrl, // Your backend URL
  headers: {
    "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
  },
  withCredentials: true, // Include credentials (like cookies) in requests
});
export default api;
