// src/components/SignUp.js
import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Container, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    businessLogo: null,
    businessName: "",
    businessAddress: "",
    businessABN: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (e) => {
    setFormData({ ...formData, businessLogo: e.target.files[0] });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setErrMsg("Passwords do not match");
      return;
    }
    setLoading(true);
    const data = new FormData();
    data.append("email", formData.email);
    data.append("password", formData.password);
    data.append("businessLogo", formData.businessLogo);
    data.append("businessName", formData.businessName);
    data.append("businessAddress", formData.businessAddress);
    data.append("businessABN", formData.businessABN);

    try {
      const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL, // Your backend URL
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // Include credentials (like cookies) in requests
      });
      await api.post("/users/signup", data);
      setLoading(false);
      navigate("/login");
    } catch (error) {
      setErrMsg(error.response.data.error);
      setLoading(false);
    }
  };

  return (
    <Container style={{ maxWidth: "600px" }}>
      <h1>Sign up</h1>
      {errMsg && <Alert variant="danger">{errMsg}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Business Logo</Form.Label>
          <Form.Control
            type="file"
            name="businessLogo"
            onChange={handleFileChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Business Name</Form.Label>
          <Form.Control
            type="text"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Business Address</Form.Label>
          <Form.Control
            type="text"
            name="businessAddress"
            value={formData.businessAddress}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Business ABN</Form.Label>
          <Form.Control
            type="number"
            name="businessABN"
            value={formData.businessABN}
            onChange={handleChange}
          />
        </Form.Group>
        <Button className="mt-3" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Sign Up"}
        </Button>
        <p className=" mt-3">
          Already have an account!, No problem juust{" "}
          <Link to="/login">Log in</Link>
        </p>
      </Form>
    </Container>
  );
};

export default SignUp;
