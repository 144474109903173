// src/components/LandingPage.js
import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import invoice from "../assets/images/invoice.png";
import "./Home.css";
import { UserContext } from "../context/UserContext";

const Home = () => {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    // <div className="jumbotron">
    <Container className="jumbotron mt-3">
      <Row className="align-items-center">
        <Col md={6}>
          <img src={invoice} alt="Invoice Maker" className="img-fluid" />
        </Col>
        <Col md={6}>
          <h1 className="display-4">Welcome to Invoice Maker</h1>
          <p className="lead">
            Simplify your invoicing process with our easy-to-use invoice
            generation tool.
          </p>
          <p>
            Create professional invoices quickly and easily. Manage your
            customers and keep track of your billing, all in one place.
          </p>
          <Button variant="primary" size="lg" as={Link} to="/signup">
            Get Started
          </Button>
        </Col>
      </Row>
    </Container>
    // </div>
  );
};

export default Home;
