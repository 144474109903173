import React, { useContext, useEffect, useState } from "react";
import axios from "../api";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  Button,
  Container,
  Table,
  Modal,
  Spinner,
  Alert,
} from "react-bootstrap";
// import { PDFViewer } from "@react-pdf/renderer";
import InvoiceForm from "./InvoiceForm";
import MyDocument from "./PDFtest";
import { UserContext } from "../context/UserContext";
import InvoiceDetails from "./InvoiceDetails";
import InvoicePrinter from "./InvoicePrinter";

const Customer = () => {
  const { id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: "", body: "" });
  const [errMsg, setErrMsg] = useState("");
  const [invoice, setInvoice] = useState(null);

  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/customers/customer/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCustomer(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    fetchCustomerDetails();
  }, [id]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/invoices/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInvoices(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    fetchInvoices();
  }, [refresh]);

  if (!customer) {
    return (
      <Container
        style={{ height: "100vh", fontSize: "250px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalInfo.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.modalInfo.body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Container className="mt-5" style={{ width: "100vw" }}>
      <Card style={{ width: "100%" }}>
        <Card.Body>
          <Card.Title>
            <h1>{customer.name}</h1>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {customer.email}
          </Card.Subtitle>

          <Card.Text>
            <strong>Address:</strong> {customer.address}
          </Card.Text>
          <h2>Invoices List</h2>
          <div>
            {errMsg && (
              <div className="error-message">
                <Alert variant="danger">{errMsg}</Alert>
              </div>
            )}
          </div>
          <Table striped bordered hover className="overflow-auto">
            <thead>
              <tr>
                <th>Number</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {invoices &&
                invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td>{invoice.invoiceNumber}</td>
                    <td>
                      {new Date(invoice.date).toLocaleDateString("en-AU")}
                    </td>
                    {/* <td>
                      <Button
                        onClick={() => {
                          setModalShow(true);
                          setModalInfo({
                            title: `${invoice.invoiceNumber}`,
                            body: (
                              <InvoicePrinter
                                invoice={invoice}
                                customer={customer}
                                user={user}
                              />
                            ),
                          });
                        }}
                      >
                        Open Invoice
                      </Button>
                    </td> */}
                    <td>
                      <Link to={`/invoice/${invoice.id}`}>
                        <button className="btn btn-primary">
                          open invoices
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Button
            onClick={() => {
              setModalShow(true);
              setModalInfo({
                title: "Create a new invoice",
                body: (
                  <InvoiceForm
                    CustomerId={customer.id}
                    customerName={customer.name}
                    setModalShow={setModalShow}
                    setRefresh={setRefresh}
                    setErrMsg={setErrMsg}
                  />
                ),
              });
            }}
          >
            Create Invoice
          </Button>
        </Card.Body>
      </Card>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalInfo={modalInfo}
      />
      {/* <Modal
        size="lg"
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Invoice PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyDocument invoice={invoice} customer={customer} user={user} />
        </Modal.Body>
      </Modal> */}
    </Container>
  );
};

export default Customer;
