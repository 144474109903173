// src/components/MyDocument.js
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
} from "@mui/material";
import axios from "../api";
import { useParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useReactToPrint } from "react-to-print";
import { Button, Modal } from "react-bootstrap";
import InvoiceEdit from "./InvoiceEdit";

const DocumentDetails = () => {
  const { user } = useContext(UserContext);
  const contentToPrint = useRef(null);
  const [invoice, setInvoice] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: "", body: "" });
  const [refresh, setRefresh] = useState(false);

  const { id } = useParams();

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
  });
  useEffect(() => {
    const fetchInviceDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const fetchedInvoice = await axios.get(`/invoices/invoice/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setInvoice(fetchedInvoice.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    fetchInviceDetails();
  }, [id, modalShow]);
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `/customers/customer/${invoice.CustomerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCustomer(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    fetchCustomerDetails();
  }, [invoice, refresh]);

  if (!invoice || !customer) {
    return null;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalInfo.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.modalInfo.body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div
      style={{
        maxWidth: "900px",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          margin: 20,
          fontSize: "1.5rem",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <button
          className="btn btn-lg btn-primary"
          onClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
        >
          PRINT
        </button>
        <button
          className="btn btn-lg btn-primary"
          onClick={() => {
            setModalShow(true);
            setModalInfo({
              title: `${invoice.invoiceNumber}`,
              body: (
                <InvoiceEdit
                  invoice={invoice}
                  setModalShow={setModalShow}
                  customer={customer}
                  setRefresh={setRefresh}
                />
              ),
            });
          }}
        >
          Edit
        </button>
      </div>
      <div style={styles.page} ref={contentToPrint}>
        <div style={styles.container}>
          <div style={styles.section}>
            <h1 style={styles.userName}>{user.businessName}</h1>
            <p style={styles.ABN}>ABN: {user.businessABN}</p>
            <p style={styles.ABN}>{user.businessAddress}</p>
            <p style={styles.ABN}>{user.email}</p>
            <div style={styles.logoContainer}>
              <img
                style={styles.logo}
                src={process.env.REACT_APP_API_URL + "/" + user.businessLogo}
                alt="Business Logo"
              />
            </div>
          </div>

          <div style={styles.customer}>
            <div>
              <p style={styles.ABN}>Customer: {customer.name}</p>
              {/* <p>Invoice Number: {invoice.invoiceNumber}</p> */}
              <p style={styles.ABN}>
                Date: {new Date(invoice.date).toLocaleDateString()}
              </p>
              <p style={styles.ABN}> {customer.address}</p>
            </div>
            <div
              style={{
                textAlign: "center",
                fontFamily: "monospace",
                color: "black",
              }}
            >
              <h2 style={styles.ABN}>Tax Invoice</h2>

              <h3 style={styles.ABN}>{invoice.invoiceNumber}</h3>
            </div>
          </div>

          <TableContainer component={Paper} style={styles.table}>
            <p>
              <strong>{invoice.desc}</strong>
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Units</StyledTableCell>
                  <StyledTableCell>Unit Price</StyledTableCell>
                  <StyledTableCell>Total Price</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.items &&
                  invoice.items.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{item.description}</StyledTableCell>
                      <StyledTableCell>{item.units}</StyledTableCell>
                      <StyledTableCell>${item.unitPrice}</StyledTableCell>
                      <StyledTableCell>${item.totalPrice}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <p>Total: ${invoice.total}</p>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalInfo={modalInfo}
      />
    </div>
  );
};

const styles = {
  page: {
    padding: 30,
    marginTop: 5,
    // border: "2px solid black",
    fontFamily: "Arial, sans-serif",
    height: "100vh",
    width: "100%",
    margin: "0 auto",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
  },
  container: {
    padding: 10,
    height: "100%",
  },
  section: {
    marginBottom: 20,
    position: "relative",
  },
  table: {
    marginBottom: 20,
    marginTop: 20,
    width: "100%",
  },
  logoContainer: {
    width: 100,
    height: 100,
    overflow: "hidden",
    position: "absolute",
    top: 0,
    right: 20,
  },
  logo: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
  userName: {
    fontSize: 27,
    marginBottom: 0,
  },
  ABN: {
    marginBottom: 0,
  },
  customer: {
    color: "#626964",
    marginBottom: 10,
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    fontFamily: "",
  },
};

export default DocumentDetails;
