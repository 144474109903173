// src/context/UserContext.js
// import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import axios from "../api";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get("/users/me", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };
    fetchUser();
  }, []);

  const login = async (credentials) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/users/login",
        credentials
      );
      localStorage.setItem("token", response.data.token);
      const userResponse = await axios.get("/users/me", {
        headers: { Authorization: `Bearer ${response.data.token}` },
      });

      setUser(userResponse.data);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    window.location.assign("/login");
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
