// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignUp from "./components/SignUp";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import CustomerForm from "./components/CustomerForm";
import InvoiceForm from "./components/InvoiceForm";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Customer from "./components/Customer";
import NotFound from "./components/NotFound";
import PDFtest from "./components/PDFtest";
import PDFviewer from "./components/PDFviewer";
import DocumentDetails from "./components/PDFviewer";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/add-customer" element={<CustomerForm />} />
        <Route path="/create-invoice" element={<InvoiceForm />} />
        <Route path="/customer/:id" element={<Customer />} />
        <Route path="/invoice/:id" element={<DocumentDetails />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
