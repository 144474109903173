import React, { useState } from "react";
import axios from "../api";
import { Form, Button, Container, Spinner, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const InvoiceEdit = ({ invoice, customer, setRefresh, setModalShow }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const [formData, setFormData] = useState({
    CustomerId: customer.CustomerId,
    date: formatDate(invoice.date),
    desc: invoice.desc,
    items: invoice.items,
    total: invoice.total,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleItemChange = (index, e) => {
    const newItems = [...formData.items];
    newItems[index][e.target.name] = e.target.value;
    setFormData({ ...formData, items: newItems });
  };

  const addItem = () => {
    setFormData({
      ...formData,
      items: [...formData.items, { description: "", units: "", unitPrice: "" }],
    });
  };

  const removeItem = (index) => {
    const newItems = formData.items.filter((_, i) => i !== index);
    setFormData({ ...formData, items: newItems });
  };

  const calculateTotal = () => {
    return formData.items.reduce((total, item) => {
      return total + item.units * item.unitPrice;
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const total = calculateTotal();
      await axios.put(
        `/invoices/update/${invoice.id}`,
        { ...formData, total },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setModalShow(false);
      navigate(`/invoice/${invoice.id}`);
      setRefresh(true);
    } catch (error) {
      setLoading(false);
      //   navigate(`/customer/${CustomerId}`);
      //   setRefresh(true);
    }
  };

  if (loading) {
    return (
      <Container
        style={{ height: "100vh", fontSize: "250px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container style={{ maxWidth: "600px" }}>
      <h1>Create Invoice</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Customer</Form.Label>
          <Form.Control type="text" value={customer.name} readOnly required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Invoice Description</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows={3}
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            required
          />
        </Form.Group>
        {formData.items.map((item, index) => (
          <div key={index} style={{ marginBottom: "1rem" }}>
            <Form.Group>
              <Form.Label>Item Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={item.description}
                onChange={(e) => handleItemChange(index, e)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Units</Form.Label>
              <Form.Control
                type="number"
                step="any" // Allows for any decimal input
                name="units"
                value={item.units}
                min={0}
                onChange={(e) => handleItemChange(index, e)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Unit Price</Form.Label>
              <Form.Control
                type="number"
                name="unitPrice"
                min={0}
                value={item.unitPrice}
                onChange={(e) => handleItemChange(index, e)}
                required
                step="any" // Allows for any decimal input
              />
            </Form.Group>
            {index > 0 && (
              <Button variant="danger" onClick={() => removeItem(index)}>
                Remove Item
              </Button>
            )}
          </div>
        ))}
        <Button variant="primary" onClick={addItem}>
          Add Item
        </Button>
        <Form.Group>
          <Form.Label>Total</Form.Label>
          <Form.Control type="text" value={calculateTotal()} readOnly />
        </Form.Group>
        <Button className="mt-3" type="submit">
          Update Invoice
        </Button>
      </Form>
    </Container>
  );
};

export default InvoiceEdit;
