import React from "react";
import ReactToPrint from "react-to-print";
import MyDocument from "./PDFtest";

const InvoicePrinter = ({ invoice, customer, user }) => {
  const componentRef = React.useRef();

  return (
    <div>
      <ReactToPrint
        trigger={() => <button>Print Invoice</button>}
        content={() => componentRef.current}
      />
      <MyDocument
        ref={componentRef}
        invoice={invoice}
        customer={customer}
        user={user}
      />
    </div>
  );
};

export default InvoicePrinter;
