// src/components/Login.js
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Container, Spinner, Alert } from "react-bootstrap";
import { UserContext } from "../context/UserContext";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    // const data = new FormData();
    // data.append("email", formData.email);
    // data.append("password", formData.password);
    e.preventDefault();
    setLoading(true);
    try {
      await login(formData);
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      setErrMsg(error);
    }
  };

  if (loading) {
    return (
      <Container
        style={{ height: "100vh", fontSize: "250px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container style={{ maxWidth: "600px" }}>
      <div>
        {errMsg && (
          <div className="error-message">
            <Alert variant="danger">{errMsg}</Alert>
            {/* <p>{error}</p> */}
          </div>
        )}
      </div>
      <h1>Log in</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button className="mt-3" type="submit">
          Login
        </Button>
        <p className=" mt-3">
          Do not have an account yet!, No problem juust{" "}
          <Link to="/signup">Sign Up</Link>
        </p>
      </Form>
    </Container>
  );
};

export default Login;
