import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { Button } from "react-bootstrap";

function NavBar() {
  const { user, logout } = useContext(UserContext);

  return (
    <Navbar className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">Invoice Maker</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            {user ? (
              <div className="d-flex  gap-3 align-items-center">
                <div className="d-flex flex-col align-items-center">
                  Signed in as: {user.businessName}
                </div>
                <div className="d-flex Nav-sm gap-3">
                  <Button style={{ color: "white" }} as={Link} to="/dashboard">
                    Dashboard
                  </Button>
                  <Button onClick={logout}>Logout</Button>
                </div>
              </div>
            ) : (
              <Link to="/login">Log in</Link>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
