// src/components/Dashboard.js
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Table,
  Spinner,
  Alert,
} from "react-bootstrap";
import { UserContext } from "../context/UserContext";
import CustomerForm from "./CustomerForm";
import InvoiceForm from "./InvoiceForm";
import axios from "../api";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modalInfo.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.modalInfo.body}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cancle</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Dashboard = () => {
  const { user, logout } = useContext(UserContext);
  const [customers, setCustomers] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [modalInfo, setModalInfo] = useState({ title: "", body: "" });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/customers/my-customers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCustomers(response.data);
        setLoading(false);
      } catch (error) {
        setErrMsg(
          " Something not right! please contact us, and provid the following error message " +
            "'" +
            error.message +
            "'"
        );

        setLoading(false);
      }
    };
    fetchCustomers();
  }, [modalShow]);

  if (loading) {
    return (
      <Container
        style={{ height: "100vh", fontSize: "250px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <>
      {user ? (
        <Container
          style={{ height: "100vh" }}
          className="d-flex justify-content-center "
        >
          <Row>
            <Col className="d-flex flex-column justify-content-centre mt-5 align-items-center">
              <div>
                <h1>Dashboard</h1>
                <p>Welcome, {user.businessName}</p>
              </div>
              <div>
                <h2>Customers List</h2>
                <div>
                  {errMsg && (
                    <div className="error-message">
                      <Alert variant="danger">{errMsg}</Alert>
                      {/* <p>{error}</p> */}
                    </div>
                  )}
                </div>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      {/* <th>Email</th>  */}
                      {/* <th>Address</th> */}
                      {/* <td>Actions</td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {customers &&
                      customers.map((customer) => (
                        <tr key={customer.id}>
                          <td>{customer.id}</td>
                          <td>{customer.name}</td>
                          {/* <td>{customer.email}</td>
                          <td>{customer.address}</td> */}
                          <td>
                            <Button as={Link} to={`/customer/${customer.id}`}>
                              Open Customer
                            </Button>
                          </td>
                          <td>
                            <Button
                              as={Link}
                              onClick={() => {
                                setModalShow(true);
                                setModalInfo({
                                  title: "Create a new invoice",
                                  body: (
                                    <InvoiceForm
                                      CustomerId={customer.id}
                                      customerName={customer.name}
                                      setModalShow={setModalShow}
                                    />
                                  ),
                                });
                              }}
                            >
                              Create Invoice
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              <div>
                <Button
                  className="m-3"
                  onClick={() => {
                    setModalShow(true);
                    setModalInfo({
                      title: "Add new customer",
                      body: <CustomerForm setModalShow={setModalShow} />,
                    });
                  }}
                >
                  Add Customer
                </Button>

                {/* <Button
                  as={Link}
                  onClick={() => {
                    setModalShow(true);
                    setModalInfo({
                      title: "Create a new invoice",
                      body: <InvoiceForm setModalShow={setModalShow} />,
                    });
                  }}
                >
                  Create Invoice
                </Button> */}
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <>Please Log in</>
      )}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalInfo={modalInfo}
      />
    </>
  );
};

export default Dashboard;
